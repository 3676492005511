module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{"style":{"overlay":{"backgroundColor":"rgba(0, 0, 0, 0.75)","zIndex":100,"display":"flex","width":"100%","minHeight":"100vh","justifyContent":"center","alignItems":"center","flexDirection":"column","padding":"40px","position":"fixed","top":0,"left":0,"overflowY":"auto"},"content":{"position":"fixed","top":"40px","left":"auto","right":"auto","bottom":"auto","border":"1px solid #ccc","background":"#fff","overflow":"auto","WebkitOverflowScrolling":"touch","borderRadius":"4px","outline":"none","padding":0,"maxWidth":"974px","width":"100%"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"strekoza.ai","short_name":"strekoza.ai","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/images/logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
