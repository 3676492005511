// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-wiki-post-js": () => import("./../src/templates/wiki-post.js" /* webpackChunkName: "component---src-templates-wiki-post-js" */),
  "component---src-templates-wiki-list-js": () => import("./../src/templates/wiki-list.js" /* webpackChunkName: "component---src-templates-wiki-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-list-js": () => import("./../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-js": () => import("./../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-password-forgot-js": () => import("./../src/pages/password-forgot.js" /* webpackChunkName: "component---src-pages-password-forgot-js" */),
  "component---src-pages-policy-js": () => import("./../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-register-activate-js": () => import("./../src/pages/register/activate.js" /* webpackChunkName: "component---src-pages-register-activate-js" */),
  "component---src-pages-register-index-js": () => import("./../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-register-success-js": () => import("./../src/pages/register/success.js" /* webpackChunkName: "component---src-pages-register-success-js" */),
  "component---src-pages-scoring-js": () => import("./../src/pages/scoring.js" /* webpackChunkName: "component---src-pages-scoring-js" */),
  "component---src-pages-user-agreement-js": () => import("./../src/pages/user-agreement.js" /* webpackChunkName: "component---src-pages-user-agreement-js" */)
}

